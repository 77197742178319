import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/root'

const overviewSelector = (state: RootState) => state.kaseOverview

/**
 * Get the email of the account owner
 */
export const getAccountHolderEmail = createSelector(
  overviewSelector,
  (overview) => {
    return overview.information.find((i) => i.key === 'Account Holder Email')
      ?.value
  }
)

// This feels hacky, but a way to get this is to see if the sponsor or bene email matches the account holder email
// and it is does, see if the corresponding role's phone number is available
export const getAccountHolderPhoneNumber = createSelector(
  overviewSelector,
  getAccountHolderEmail,
  (overview, accountHolderEmail) => {
    const sponsorEmail = overview.contact_info.sponsor_email
    const beneEmail = overview.contact_info.bene_email
    if (sponsorEmail === accountHolderEmail) {
      return overview.contact_info.sponsor_phone_number
    }
    if (beneEmail === accountHolderEmail) {
      return overview.contact_info.bene_phone_number
    }
    return null
  }
)
